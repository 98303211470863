<div class="mb-3" *ngIf="['pending', 'processing'].includes(transaction.status) && transaction.type === 'deposit'">
    <h4 class="text-primary mb-2">{{ 'common.make-transfer' | translate }}</h4>
    <div class="d-flex align-items-center">
        <div class="text-center p-2">
            <fa-icon class="text-primary" [icon]="faPhoneLaptop" size="3x"></fa-icon>
            <div class="font-sm text-muted mt-3">{{ 'common.open-online-banking' | translate }}</div>
        </div>
        <fa-icon class="text-primary" [icon]="faArrowRight" size="md"></fa-icon>
        <div class="text-center p-2">
            <fa-icon class="text-primary" [icon]="faPaperPlane" size="3x"></fa-icon>
            <div class="font-sm text-muted mt-3">{{ 'common.make-a-transfer' | translate }}</div>
        </div>
        <fa-icon class="text-primary" [icon]="faArrowRight" size="md"></fa-icon>
        <div class="text-center p-2">
            <fa-icon class="text-primary" [icon]="faReceipt" size="3x"></fa-icon>
            <div class="font-sm text-muted mt-3">{{ 'common.save-payment-document' | translate }}</div>
        </div>
    </div>
</div>
<div class="mb-3">
    <h4 class="text-primary mb-2">{{ 'common.bank-details' | translate }}</h4>
    <deposit-instruction-table [beneficiary]="transaction.beneficiary" [transaction]="transaction" [showWarning]="true"></deposit-instruction-table>
</div>
