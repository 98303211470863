import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import addSeconds from 'date-fns/addSeconds'
import getUnixTime from 'date-fns/getUnixTime'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import { Base64 } from 'js-base64'
import { BehaviorSubject, from, Observable, ReplaySubject, Subject } from 'rxjs'
import { debounceTime, filter, flatMap, map, take, tap } from 'rxjs/operators'
import { StorageService } from './storage.service'
import { ICurrency, IUser, Permission, RefreshToken, UserRole, UserStatus, UserType } from '../api-interfaces'
import { NoAccessError } from '../no-access-modal/no-access-modal.component'

export enum UserEventType {
    LOGIN = 'login',
    LOGOUT = 'logout',
}

export interface UserEvent {
    type: UserEventType
    user: User
}

export class User implements Partial<IUser> {
    public id: string
    public type: UserType
    public accountNumber: string
    public email: string
    public permissions: Permission[]
    public role: UserRole
    public status: UserStatus
    public name: string
    public preferredCurrency: ICurrency
    public twoFactor: boolean
    public lastLogin: string
    public loginCount: number
    public intercomHash: string
    public approvedAt: Date | null
    public billingCurrency: ICurrency
    public sendEmailNotifications: boolean
    public association: { id: string; name: string }
    public multiSig: number
    public createdAt: string
    public updatedAt: string

    public hasPermission(required: string): boolean {
        const [primary, secondary] = required.split(':')

        const userPermission = this.permissions.find(p => p.includes(primary))
        if (!userPermission) {
            return false
        }

        if (secondary) {
            const [_, userSecondary] = userPermission.split(':')
            if (userSecondary) {
                const userSecondaryItems = userSecondary.split(',')

                if (secondary.split(',').some(p => !userSecondaryItems.includes(p))) {
                    return false
                }
            }
        }
        return true
    }

    public get accessErrors(): NoAccessError[] {
        const errors: NoAccessError[] = []
        if (this.role === 'admin') {
            return errors
        }
        if (!this.approvedAt) {
            errors.push('VerificationRequiredError')
        }
        if (this.status === 'frozen') {
            errors.push('AccountFrozenError')
        }
        return errors
    }
}

export interface TokenPayload {
    iat: number
    iss: number
    exp: number
    userID: string
    signatoryID: string | null
    scopes: TokenScope[]
}

export type TokenScope = 'securitySettings' | 'banking'

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    // TODO: Remove when using observable everywhere
    public get user(): User {
        return this.userChangeEvent.getValue()!
    }

    public refreshToken?: RefreshToken
    public token?: string
    public tokenChangeEvent = new Subject<void>()
    /* Emits when the status of the user changes */
    public userEvent = new ReplaySubject<UserEvent>(1)
    public userStream: Observable<User>
    private userChangeEvent = new BehaviorSubject<User | null>(null)
    private payload?: TokenPayload

    constructor(private storage: StorageService, private http: HttpClient) {
        this.userStream = this.userChangeEvent.asObservable().pipe(filter((user): user is User => !!user))
        this.storage.storageChange
            .pipe(
                filter(event => {
                    switch (event.key) {
                        case 'refreshToken':
                        case 'token':
                        case 'user':
                            return true
                        default:
                            // not needed
                            return false
                    }
                }),
                debounceTime(200)
            )
            .subscribe(() => {
                this.syncWithStorage()
            })
        // initial sync
        this.syncWithStorage()
    }

    public login(refreshToken: RefreshToken, token: string): Observable<User> {
        return this.update(refreshToken, token).pipe(
            tap(user => {
                this.userEvent.next({ type: UserEventType.LOGIN, user })
            })
        )
    }

    public update(refreshToken?: RefreshToken, token?: string): Observable<User> {
        try {
            if (refreshToken && token) {
                this.refreshToken = refreshToken
                this.token = token
                this.payload = this.parseToken(token)
                this.tokenChangeEvent.next()
            }
            return this.http.get<IUser>(`/users/${this.payload!.userID}?scope=session`).pipe(
                map(data => {
                    const user = Object.assign(new User(), data)
                    this.userChangeEvent.next(user)
                    this.storage.setItem('refreshToken', JSON.stringify(this.refreshToken))
                    this.storage.setItem('token', this.token!)
                    this.storage.setItem('user', JSON.stringify(data))
                    return user
                })
            )
        } catch (error) {
            this.logout()
            throw error
        }
    }

    public logout(): void {
        this.refreshToken = undefined
        this.token = undefined
        this.payload = undefined
        // notify others of logout
        this.userEvent.next({ type: UserEventType.LOGOUT, user: this.userChangeEvent.getValue()! })
        this.userChangeEvent.next(null)
        // clear storage
        this.storage.removeItem('refreshToken')
        this.storage.removeItem('token')
        this.storage.removeItem('user')
    }

    /**
     * Checks if a token is stored and not yet expired
     * @param gracePeriod in seconds. Returns if the token is at least S seconds from now valid.
     */
    public hasValidToken(gracePeriod = 0): boolean {
        // check if token is set and is not yet expired
        return !!this.payload && this.payload.exp > (Date.now() + gracePeriod) / 1000
    }

    public hasTokenScope(scope: TokenScope): boolean {
        return this.getTokenScopes().includes(scope)
    }

    public getTokenScopes(): TokenScope[] {
        return this.hasValidToken() ? this.payload!.scopes : []
    }

    public getExpiryTimeInSeconds(): number {
        const expiryTime = this.payload && this.payload.exp
        return expiryTime ? Math.round(expiryTime - ~~(new Date().getTime() / 1000)) : 0
    }

    /**
     * Checks if a refresh token is stored and not yet expired
     * @param gracePeriod in seconds. Returns if the token is at least S seconds from now valid.
     */
    public hasValidRefreshToken(gracePeriod = 0): boolean {
        return (
            !!this.refreshToken && !isBefore(parseISO(this.refreshToken.expiresAt), addSeconds(new Date(), gracePeriod))
        )
    }

    public isAuthenticated(): boolean {
        return this.userChangeEvent.getValue() !== null
    }

    public createSignedUrl(url: string, queryParams?: string): Observable<string> {
        const now = getUnixTime(new Date())
        const encoder = new TextEncoder()
        return this.userStream.pipe(
            take(1),
            flatMap(user =>
                from(
                    crypto.subtle.digest('SHA-1', encoder.encode(user.id + `/.api${url}` + now + this.refreshToken?.id))
                )
            ),
            map(signature =>
                Array.from(new Uint8Array(signature))
                    .map(b => b.toString(16).padStart(2, '0'))
                    .join('')
            ),
            map(
                signatureInHex =>
                    `/.api${url}?userId=${this.user.id}&signature=${signatureInHex}&timestamp=${now}${
                        queryParams ? '&' + queryParams : ''
                    }`
            )
        )
    }

    public getSignatoryId(): string | null {
        return this.payload?.signatoryID ?? null
    }

    /**
     * Use to hide certain parts of the app for the hkmso demo account.
     */
    public isHkmso(): boolean {
        return this.user && this.user.id === '6d77d2d3-c694-4881-bc38-c37040ad30c5'
    }

    private syncWithStorage(): void {
        try {
            if (this.storage.hasItem('user')) {
                const user = JSON.parse(this.storage.getItem('user')!)
                Object.assign(this.userChangeEvent, { _value: Object.assign(new User(), user) })
            }
            const refreshToken =
                this.storage.hasItem('refreshToken') && JSON.parse(this.storage.getItem('refreshToken')!)
            const token = this.storage.getItem('token')
            // if is expired
            if (refreshToken && isBefore(parseISO(refreshToken.expiresAt), new Date())) {
                throw new Error('Session expired')
            }
            if (refreshToken && token) {
                this.refreshToken = refreshToken
                this.token = token
                this.payload = this.parseToken(token)
                this.userEvent.next({ type: UserEventType.LOGIN, user: { id: this.payload?.userID } as any })
            } else if (this.isAuthenticated()) {
                this.logout()
            }
        } catch {
            this.storage.removeItem('refreshToken')
            this.storage.removeItem('token')
            this.storage.removeItem('user')
            if (this.isAuthenticated()) {
                this.logout()
            }
        }
    }

    private parseToken(token: string): TokenPayload {
        return JSON.parse(Base64.decode(token.split('.')[1]))
    }
}
