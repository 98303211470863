<nav class="navbar navbar-dashboard px-0 e2e-header">
    <div [ngClass]="router.isActive('/admin', false) ? 'container-fluid' : 'container'">
        <ul class="nav navbar-nav">
            <li class="nav-item d-flex align-items-center">
                <button type="button" class="btn btn-transparent me-1 d-lg-none" (click)="openMobileNav()">
                    <fa-icon [fixedWidth]="true" [icon]="faBars"></fa-icon>
                </button>
                <button type="button" class="btn btn-transparent me-1 d-none d-lg-block" (click)="toggleSidebar()">
                    <fa-icon [fixedWidth]="true" [icon]="faBars"></fa-icon>
                </button>
            </li>
            <ng-container *ngIf="!router.isActive('/admin', false)">
                <li class="nav-item d-none d-lg-flex align-items-center">
                    <button type="button" class="btn btn-transparent" [routerLink]="['/transactions']">
                        <fa-icon class="me-1" [fixedWidth]="true" [icon]="faSearch"></fa-icon> {{ 'header.search' | translate }}
                    </button>
                </li>
            </ng-container>
            <ng-container *ngIf="router.isActive('/admin', false)">
                <li class="nav-item d-none d-lg-flex align-items-center">
                    <online-users-count></online-users-count>
                </li>
            </ng-container>
        </ul>
        <ul class="nav navbar-nav" *ngIf="session.userStream | async; let user">
            <ng-container *ngIf="!router.isActive('/admin', false)">
                <li class="nav-item d-flex align-items-center e2e-header-mailbox">
                    <header-mailbox></header-mailbox>
                </li>
                <li class="nav-item d-flex align-items-center e2e-header-notifications">
                    <header-notifications></header-notifications>
                </li>
                <li *ngIf="(whitelabel.whitelabel$ | async) === null" class="nav-item d-flex align-items-center">
                    <header-news></header-news>
                </li>
            </ng-container>
            <ng-container *ngIf="router.isActive('/admin', false)">
                <li class="nav-item d-flex align-items-center" *ngIf="appName === 'aerapass'">
                    <button
                        type="button"
                        class="btn btn-transparent me-1"
                        [routerLink]="['/admin/screening']"
                        placement="left"
                        ngbTooltip="Screening Tool"
                    >
                        <fa-icon [fixedWidth]="true" [icon]="faSearchPlus"></fa-icon>
                    </button>
                </li>
                <li class="nav-item d-flex align-items-center" *ngIf="appName === 'aerapass'">
                    <button
                        type="button"
                        class="btn btn-transparent me-1"
                        [routerLink]="['/admin/cross-env']"
                        placement="left"
                        ngbTooltip="Cross-Env Tool"
                    >
                        <fa-icon [fixedWidth]="true" [icon]="faTools"></fa-icon>
                    </button>
                </li>
                <li class="nav-item d-flex align-items-center">
                    <button type="button" class="btn btn-transparent me-1" (click)="openGlobalMessageModal()" placement="left" ngbTooltip="Messenger">
                        <fa-icon [fixedWidth]="true" [icon]="faPaperPlane"></fa-icon>
                    </button>
                </li>
            </ng-container>
            <li class="nav-item d-none d-lg-flex align-items-center">
                <button
                    type="button"
                    class="btn btn-transparent"
                    placement="left"
                    [ngbTooltip]="'common.reset-session-timeout' | translate"
                    (click)="logoutTimerService.reset()"
                >
                    <fa-icon [fixedWidth]="true" [icon]="faClock" class="me-1"></fa-icon>
                    <span class="font-sm text-gray">
                        {{ sessionTimeLeft$ | async }}
                    </span>
                </button>
            </li>
            <li class="nav-item d-flex align-items-center e2e-account-button" ngbDropdown placement="bottom-right">
                <button type="button" class="btn btn-transparent" ngbDropdownToggle>
                    <fa-icon [fixedWidth]="true" [icon]="faUserCircle" class="me-1"></fa-icon>
                    <fa-icon [fixedWidth]="true" [icon]="faAngleDown"></fa-icon>
                </button>
                <div class="dropdown-menu-lg dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item d-flex flex-column text-center py-3" [routerLink]="['/account/profile']">
                        <div>
                            <user-avatar [user]="user" size="md" elementClass="mb-2"></user-avatar>
                        </div>
                        <div class="text-primary">{{ user.fullname }}</div>
                        <div class="small text-body">{{ user.email }}</div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a ngbDropdownItem [routerLink]="['/account/security']">{{ 'header.change-password' | translate }}</a>
                    <a ngbDropdownItem [routerLink]="!router.isActive('/admin', false) ? ['/admin'] : ['/dashboard']" *ngIf="user.role !== null">{{
                        (!router.isActive('/admin', false) ? 'header.administration' : 'header.back-to-dashboard') | translate
                    }}</a>
                    <a
                        ngbDropdownItem
                        [routerLink]="!router.isActive('/merchant', false) ? ['/merchant'] : ['/dashboard']"
                        *ngIf="user.type === 'business'"
                        >{{ (!router.isActive('/merchant', false) ? 'common.merchant-dashboard' : 'header.back-to-dashboard') | translate }}</a
                    >
                    <div class="dropdown-divider"></div>
                    <a ngbDropdownItem href (click)="logout(); $event.preventDefault()">{{ 'header.logout' | translate }}</a>
                </div>
            </li>
        </ul>
    </div>
</nav>
