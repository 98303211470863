import { CurrencyMaskModule } from '@aerapass/ng-currency-mask'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@aerapass/ng-pick-datetime'
import { CommonModule as AngularCommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import {
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap'
import { NgSelectModule } from '@ng-select/ng-select'
import { TranslateModule } from '@ngx-translate/core'
import { ngfModule } from 'angular-file'
import { QRCodeModule } from 'angularx-qrcode'
import { NgOtpInputModule } from 'ng-otp-input'
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs'
import { NgxMaskModule } from 'ngx-mask'
import { AccessErrorsComponent } from './access-error/access-errors.component'
import { AccountNumberComponent } from './account-number/acount-number.component'
import { AccountNumberPipe } from './account-number.pipe'
import { AddIconComponent } from './add-icon.component'
import { AddressFormComponent } from './address-form/address-form.component'
import { AlertOutletComponent } from './alert-outlet/alert-outlet.component'
import { AmountPipe } from './amount.pipe'
import { TokenRequestInterceptor } from './auth.interceptor'
import { AvatarUploadComponent } from './avatar-upload/avatar-upload.component'
import { BeneficiaryDetailsFormComponent } from './beneficiary-form/beneficiary-details-form/beneficiary-details-form.component'
import { BeneficiaryFormComponent } from './beneficiary-form/beneficiary-form.component'
import { CapitalizePipe } from './capitalize.pipe'
import { CardFeeScheduleComponent } from './card-fee-schedule/card-fee-schedule.component'
import { CircleSpinnerComponent } from './circle-spinner/circle-spinner.component'
import { CollapsibleComponent } from './collapsible/collapsible.component'
import { ConfirmMailReminderComponent } from './confirm-mail-reminder/confirm-mail-reminder.component'
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component'
import { ContactBookComponent } from './contact-book/contact-book.component'
import { CopyButtonComponent } from './copy-btn/copy-btn.component'
import { CopyIconComponent } from './copy-icon/copy-icon.component'
import { CopyWrapComponent } from './copy-wrap/copy-wrap.component'
import { CryptoQrCodeComponent } from './crypto-qrcode/crypto-qrcode.component'
import { CurrencySelectComponent } from './currency-select/currency-select.component'
import { CurrencySelectTabComponent } from './currency-select-tab/currency-select-tab.component'
import { DepositInstructionComponent } from './deposit-instruction/deposit-instruction.component'
import { DepositInstructionTableComponent } from './deposit-instruction-table/deposit-instruction-table.component'
import { DropzoneComponent } from './dropzone/dropzone.component'
import { EmptyToNullDirective } from './emptytonull.directive'
import { ErrorHandlerInterceptor } from './errorhandler.interceptor'
import { FeePreviewComponent } from './fee-preview/fee-preview.component'
import { FocusDirective } from './focus.directive'
import { FormatDatePipe } from './format-date.pipe'
import { FormatDistanceToNowPipe } from './format-distance-to-now.pipe'
import { FundsUnderManagementComponent } from './funds-under-management/funds-under-management.component'
import { ImageComponent } from './image.component'
import { IsBeforePipe } from './is-before.pipe'
import { ListComponent } from './list.component'
import { MaskedPipe } from './masked.pipe'
import { MatchPasswordDirective } from './match-password.directive'
import { MaxFileSizeDirective } from './max-file-size.directive'
import { NoAccessModalComponent } from './no-access-modal/no-access-modal.component'
import { NumericDirective } from './numeric.directive'
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component'
import { PasswordStrengthDirective } from './password-strength.directive'
import { PercentagePipe } from './percentage.pipe'
import { PermissionSelectComponent } from './permission-select/permission-select.component'
import { PluralizePipe } from './pluralize.pipe'
import { PostalAddressComponent } from './postal-address/postal-address.component'
import { PrettyPrintPipe } from './pretty-print.pipe'
import { FileItemComponent } from './profile-documents/file-item.component'
import { ProfileDocumentsComponent } from './profile-documents/profile-documents.component'
import { UserFileFormComponent } from './profile-documents/user-file-form/user-file-form.component'
import { SafeHtmlPipe } from './safe-html.pipe'
import { SafeResourceUrlPipe } from './safe-resource-url.pipe'
import { SafeUrlPipe } from './safe-url.pipe'
import { ConfirmPasswordModalComponent } from './security-check/confirm-password.modal'
import { SentenceCasePipe } from './sentence-case.pipe'
import { StartCasePipe } from './start-case.pipe'
import { TransactionDocumentsComponent } from './transaction-documents/transaction-documents.component'
import { TruncatePipe } from './truncate.pipe'
import { TwoFactorModalComponent } from './two-factor-modal/two-factor.modal.component'
import { UserComponent } from './user/user.component'
import { UserAvatarComponent } from './user-avatar/user-avatar.component'
import { UserBalanceComponent } from './user-balance/user-balance.component'
import { UserDetailsFormComponent } from './user-details-form/user-details-form.component'
import { UserInfoTableComponent } from './user-info-table/user-info-table.component'
import { UuidComponent } from './uuid/uuid.component'
import { ValidateDirective } from './validate.directive'
import {
    BirthdayValidatorDirective,
    ColorValidatorDirective,
    CustomValidatorDirective,
    DecimalValidatorDirective,
    IntegerValidatorDirective,
    MaxValidatorDirective,
    MinValidatorDirective,
    NumberValidatorDirective,
    RecipientValidatorDirective,
    NameValidatorDirective,
    IpAddressValidatorDirective,
} from './validators'
import { VideoPlayerComponent } from './video-player/video-player.component'
import { TwoFactorComponent } from '../account/account-security/two-factor/two-factor.component'

const DECLARATIONS = [
    AccessErrorsComponent,
    AccountNumberComponent,
    AccountNumberPipe,
    AddressFormComponent,
    AlertOutletComponent,
    AmountPipe,
    BeneficiaryDetailsFormComponent,
    BeneficiaryFormComponent,
    BirthdayValidatorDirective,
    RecipientValidatorDirective,
    CircleSpinnerComponent,
    ColorValidatorDirective,
    ConfirmationModalComponent,
    ConfirmMailReminderComponent,
    ConfirmPasswordModalComponent,
    ContactBookComponent,
    CopyButtonComponent,
    CopyIconComponent,
    CopyWrapComponent,
    CurrencySelectComponent,
    CurrencySelectTabComponent,
    CustomValidatorDirective,
    DecimalValidatorDirective,
    DropzoneComponent,
    EmptyToNullDirective,
    UserAvatarComponent,
    UserComponent,
    FeePreviewComponent,
    FileItemComponent,
    FocusDirective,
    FormatDatePipe,
    FormatDistanceToNowPipe,
    IntegerValidatorDirective,
    ListComponent,
    MatchPasswordDirective,
    MaxValidatorDirective,
    MinValidatorDirective,
    NameValidatorDirective,
    IpAddressValidatorDirective,
    NoAccessModalComponent,
    NumberValidatorDirective,
    NumericDirective,
    PasswordStrengthBarComponent,
    PasswordStrengthDirective,
    PermissionSelectComponent,
    PostalAddressComponent,
    ProfileDocumentsComponent,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    SafeUrlPipe,
    StartCasePipe,
    CapitalizePipe,
    TruncatePipe,
    UserFileFormComponent,
    UserDetailsFormComponent,
    UserInfoTableComponent,
    UuidComponent,
    ValidateDirective,
    VideoPlayerComponent,
    DepositInstructionComponent,
    DepositInstructionTableComponent,
    CardFeeScheduleComponent,
    MaskedPipe,
    PluralizePipe,
    IsBeforePipe,
    SentenceCasePipe,
    ImageComponent,
    CollapsibleComponent,
    AddIconComponent,
    CryptoQrCodeComponent,
    TransactionDocumentsComponent,
    AvatarUploadComponent,
    TwoFactorModalComponent,
    UserBalanceComponent,
    FundsUnderManagementComponent,
    TwoFactorComponent,
    PercentagePipe,
    PrettyPrintPipe,
    MaxFileSizeDirective,
]

const MODULES = [
    AngularCommonModule,
    CurrencyMaskModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    ngfModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbCollapseModule,
    FontAwesomeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbDatepickerModule,
    HighlightModule,
    TranslateModule,
    QRCodeModule,
    NgOtpInputModule,
]

@NgModule({
    declarations: [...DECLARATIONS],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenRequestInterceptor,
            multi: true,
        },
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                fullLibraryLoader: () => import('highlight.js'),
            },
        },
    ],
    imports: [NgxMaskModule.forRoot(), ...MODULES],
    exports: [...DECLARATIONS, ...MODULES, NgxMaskModule],
})
export class CommonModule {}
