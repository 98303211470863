<form #detailsForm="ngForm" [ngClass]="{ 'ng-submitted': submitted }" class="form-validation" novalidate>
    <ul *ngIf="type === 'business'" class="nav nav-tabs nav-tabs-lg mb-2">
        <li class="nav-item">
            <a href class="nav-link" [ngClass]="{ active: currentTab === 'business' }" (click)="currentTab = 'business'; $event.preventDefault()">
                {{ 'common.business' | translate }} Details
            </a>
        </li>
        <li class="nav-item">
            <a href class="nav-link" [ngClass]="{ active: currentTab === 'manager' }" (click)="currentTab = 'manager'; $event.preventDefault()">
                Account {{ 'common.manager' | translate }}
            </a>
        </li>
    </ul>
    <ng-container *ngIf="type === 'personal' || (type === 'business' && currentTab === 'manager'); else businessDetails">
        <div class="max-width-lg">
            <div class="row">
                <div class="form-group col-sm-12">
                    <label class="form-control-label" for="firstName">{{ 'common.first-name' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="firstName"
                        name="firstName"
                        [(ngModel)]="details.firstName"
                        #firstName="ngModel"
                        [disabled]="disabled"
                        required
                        emptyToNull
                        nameValidator
                    />
                    <div *ngIf="firstName.invalid && (submitted || firstName.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="firstName?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                        <div class="form-control-feedback" *ngIf="firstName?.errors?.nameError">
                            {{ 'common.only-alphabets-are-allowed' | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-12">
                    <label class="form-control-label" for="lastName">{{ 'common.last-name' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="lastName"
                        name="lastName"
                        [(ngModel)]="details.lastName"
                        #lastName="ngModel"
                        [disabled]="disabled"
                        required
                        emptyToNull
                        nameValidator
                    />
                    <div *ngIf="lastName.invalid && (submitted || lastName.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="lastName?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                        <div class="form-control-feedback" *ngIf="lastName?.errors?.nameError">
                            {{ 'common.only-alphabets-are-allowed' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12">
                    <label class="form-control-label" for="middleName"
                        >{{ 'common.middle-name' | translate }} <span class="text-muted">({{ 'common.optional' | translate }})</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="middleName"
                        name="middleName"
                        [(ngModel)]="details.middleName"
                        #middleName="ngModel"
                        [disabled]="disabled"
                        emptyToNull
                        nameValidator
                    />
                    <div *ngIf="middleName.invalid && (submitted || middleName.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="middleName?.errors?.nameError">
                            {{ 'common.only-alphabets-are-allowed' | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-12">
                    <label class="form-control-label" for="gender">{{ 'common.gender' | translate }}</label>
                    <ng-select
                        id="gender"
                        name="gender"
                        [(ngModel)]="details.gender"
                        [items]="genders"
                        [clearable]="false"
                        [searchable]="false"
                        #gender="ngModel"
                        [required]="requireAll"
                        [disabled]="disabled"
                    >
                        <ng-template ng-label-tmp let-item="item"
                            ><span class="text-capitalize">{{ item }}</span></ng-template
                        >
                        <ng-template ng-option-tmp let-item="item"
                            ><span class="text-capitalize">{{ item }}</span></ng-template
                        >
                    </ng-select>
                    <div *ngIf="gender.invalid && (submitted || gender.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="gender?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-12">
                    <label class="form-control-label" for="dateOfBirth">{{ 'common.dateOfBirth' | translate }}</label>
                    <input
                        class="form-control"
                        type="text"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        [(ngModel)]="dateOfBirth"
                        mask="00/00/0000"
                        #dateOfBirthField="ngModel"
                        [required]="requireAll"
                        [disabled]="disabled"
                        dateOfBirthValidator
                    />
                    <small class="form-text text-muted">{{ 'common.format' | translate }}: dd/mm/yyyy</small>
                    <div *ngIf="dateOfBirthField.invalid && (submitted || dateOfBirthField.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="dateOfBirthField?.errors?.required">
                            {{ 'common.this-is-required' | translate }}
                        </div>
                        <div class="form-control-feedback" *ngIf="dateOfBirthField?.errors?.invalidDate">{{ 'common.invalid-date' | translate }}</div>
                        <div class="form-control-feedback" *ngIf="dateOfBirthField?.errors?.dateLongAgo">
                            {{ 'common.date-should-be-1900' | translate }}
                        </div>
                        <div class="form-control-feedback" *ngIf="dateOfBirthField?.errors?.dateInFuture">
                            {{ 'common.you-should-18' | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-12">
                    <label class="form-control-label" for="citizenship">Citizenship</label>
                    <ng-select
                        id="citizenship"
                        name="citizenship"
                        [(ngModel)]="details.citizenship"
                        bindLabel="label"
                        [items]="countriesSortedByName"
                        [clearable]="false"
                        #citizenship="ngModel"
                        autocomplete="false"
                        [disabled]="disabled"
                        [required]="requireAll"
                    ></ng-select>
                    <div *ngIf="citizenship.invalid && (submitted || citizenship.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="citizenship?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-sm-8">
                    <label class="form-control-label" for="phoneCountryCode">{{ 'common.country-code' | translate }}</label>
                    <ng-select
                        id="phoneCountryCode"
                        name="phoneCountryCode"
                        [(ngModel)]="phoneCountryCode"
                        bindLabel="alpha2"
                        [items]="countries"
                        [searchFn]="searchCountryCode"
                        [clearable]="false"
                        #phoneCountryCodeField="ngModel"
                        autocomplete="false"
                        [disabled]="disabled"
                        [required]="requireAll"
                    >
                        <ng-template ng-label-tmp let-item="item">{{ item.alpha2 }} {{ item.countryCallingCodes[0] }}</ng-template>
                        <ng-template ng-option-tmp let-item="item">{{ item.alpha2 }} {{ item.countryCallingCodes[0] }}</ng-template>
                    </ng-select>
                    <div *ngIf="phoneCountryCodeField.invalid && (submitted || phoneCountryCodeField.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="phoneCountryCodeField?.errors?.required">
                            {{ 'common.this-is-required' | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-16">
                    <label class="form-control-label" for="phoneNumber">{{ 'common.phone-number' | translate }}</label>
                    <div class="input-group">
                        <span class="input-group-text">
                            <fa-icon [icon]="faPhone"></fa-icon>
                        </span>
                        <input
                            type="text"
                            class="form-control"
                            id="phoneNumber"
                            name="phoneNumber"
                            [(ngModel)]="details.phoneNumber"
                            #phoneNumber="ngModel"
                            [required]="requireAll"
                            [disabled]="disabled"
                            emptyToNull
                        />
                    </div>
                    <div *ngIf="phoneNumber.invalid && (submitted || phoneNumber.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="phoneNumber?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                </div>
            </div>
            <address-form [(address)]="details.address" [disabled]="disabled" [requireAll]="requireAll" [compact]></address-form>
            <div class="form-group">
                <label class="form-control-label" for="occupation">{{ 'user-details-form.occupation' | translate }}</label>
                <ng-select
                    id="occupation"
                    name="occupation"
                    [(ngModel)]="details.occupation"
                    bindLabel="label"
                    [items]="occupationOptions"
                    [clearable]="false"
                    #occupation="ngModel"
                    autocomplete="false"
                    [disabled]="disabled"
                    [required]="requireAll"
                ></ng-select>
                <div *ngIf="occupation.invalid && (submitted || occupation.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="occupation?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-control-label" for="sourceOfIncome">{{ 'user-details-form.source-of-income' | translate }}</label>
                <ng-select
                    id="sourceOfIncome"
                    name="sourceOfIncome"
                    [(ngModel)]="details.sourceOfIncome"
                    bindLabel="label"
                    [items]="sourceOfIncomeOptions"
                    [clearable]="false"
                    #sourceOfIncome="ngModel"
                    autocomplete="false"
                    [disabled]="disabled"
                    [required]="requireAll"
                ></ng-select>
                <div *ngIf="sourceOfIncome.invalid && (submitted || sourceOfIncome.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="sourceOfIncome?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="productsAndServices"> Products and Services</label>
                <ng-select
                    id="productsAndServices"
                    name="productsAndServices"
                    [(ngModel)]="details.productsAndServices"
                    bindLabel="label"
                    [items]="serviceOptions"
                    [searchable]="false"
                    [clearable]="false"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    #productsAndServices="ngModel"
                    autocomplete="false"
                    [disabled]="disabled"
                    [required]="requireAll"
                ></ng-select>
                <div *ngIf="productsAndServices.invalid && (submitted || productsAndServices.dirty)" class="error-messages">
                    <div class="form-control-feedback" *ngIf="productsAndServices?.errors?.required">
                        {{ 'common.this-is-required' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #businessDetails>
        <ng-container *ngIf="currentTab === 'business'">
            <div class="max-width-lg">
                <div class="form-group">
                    <label class="form-control-label" for="businessName">{{ 'common.entity-name' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="businessName"
                        name="businessName"
                        [(ngModel)]="business.name"
                        #businessName="ngModel"
                        [disabled]="disabled"
                        required
                        emptyToNull
                    />
                    <div *ngIf="businessName.invalid && (submitted || businessName.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="businessName?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <label class="form-control-label" for="registrationNumber">{{ 'user-details-form.registration-number' | translate }}</label>
                        <input
                            type="text"
                            class="form-control"
                            id="registrationNumber"
                            name="registrationNumber"
                            [(ngModel)]="business.registrationNumber"
                            #registrationNumber="ngModel"
                            [disabled]="disabled"
                            [required]="requireAll"
                            emptyToNull
                        />
                        <div *ngIf="registrationNumber.invalid && (submitted || registrationNumber.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="registrationNumber?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label class="form-label" for="countryOfIncorporation"> {{ 'common.country-of-incorporation' | translate }} </label>
                        <ng-select
                            id="countryOfIncorporation"
                            name="countryOfIncorporation"
                            [(ngModel)]="business.countryOfIncorporation"
                            bindLabel="label"
                            [items]="countriesSortedByName"
                            [clearable]="false"
                            #countryOfIncorporation="ngModel"
                            autocomplete="false"
                            [disabled]="disabled"
                            [required]="requireAll"
                        ></ng-select>
                        <div *ngIf="countryOfIncorporation.invalid && (submitted || countryOfIncorporation.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="countryOfIncorporation?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <label class="form-control-label" for="duns">DUNS</label>
                        <input
                            type="text"
                            class="form-control"
                            id="duns"
                            name="duns"
                            [(ngModel)]="business.duns"
                            #duns="ngModel"
                            [disabled]="disabled"
                            [required]="requireAll"
                            emptyToNull
                        />
                        <div *ngIf="duns.invalid && (submitted || duns.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="duns?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label class="form-control-label" for="legalStructure"> Legal Structure </label>
                        <ng-select
                            id="legalStructure"
                            name="legalStructure"
                            [(ngModel)]="business.legalStructure"
                            bindLabel="label"
                            [items]="legalStructureOptions"
                            [clearable]="false"
                            #legalStructure="ngModel"
                            autocomplete="false"
                            [disabled]="disabled"
                            [required]="requireAll"
                        ></ng-select>
                        <div *ngIf="legalStructure.invalid && (submitted || legalStructure.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="legalStructure?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-sm-12">
                        <label class="form-label" for="industry"> {{ 'common.industry' | translate }} </label>
                        <ng-select
                            id="industry"
                            name="industry"
                            [(ngModel)]="business.industry"
                            bindLabel="label"
                            [items]="industryOptions"
                            [clearable]="false"
                            #industry="ngModel"
                            autocomplete="false"
                            [disabled]="disabled"
                            [required]="requireAll"
                        ></ng-select>
                        <div *ngIf="industry.invalid && (submitted || industry.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="industry?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label class="form-label" for="purpose"> Purpose of Business </label>
                        <ng-select
                            id="purpose"
                            name="purpose"
                            [(ngModel)]="business.purpose"
                            bindLabel="label"
                            [items]="businessPurposeOptions"
                            [clearable]="false"
                            #purpose="ngModel"
                            autocomplete="false"
                            [disabled]="disabled"
                            [required]="requireAll"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                {{ item.label }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                {{ item.label }}
                            </ng-template>
                        </ng-select>
                        <div *ngIf="purpose.invalid && (submitted || purpose.dirty)" class="error-messages">
                            <div class="form-control-feedback" *ngIf="purpose?.errors?.required">
                                {{ 'common.this-is-required' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="productsAndServices"> Products and Services</label>
                    <ng-select
                        id="productsAndServices"
                        name="productsAndServices"
                        [(ngModel)]="details.productsAndServices"
                        bindLabel="label"
                        [items]="serviceOptions"
                        [searchable]="false"
                        [clearable]="false"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        #productsAndServices="ngModel"
                        autocomplete="false"
                        [disabled]="disabled"
                        [required]="requireAll"
                    ></ng-select>
                    <div *ngIf="productsAndServices.invalid && (submitted || productsAndServices.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="productsAndServices?.errors?.required">
                            {{ 'common.this-is-required' | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="website">Website</label>
                    <input
                        type="text"
                        class="form-control"
                        id="website"
                        name="website"
                        [(ngModel)]="business.website"
                        #website="ngModel"
                        [disabled]="disabled"
                        required
                        emptyToNull
                    />
                    <div *ngIf="website.invalid && (submitted || website.dirty)" class="error-messages">
                        <div class="form-control-feedback" *ngIf="website?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
                    </div>
                </div>
                <div class="pt-2 mb-1"></div>
                <div class="d-flex align-items-center">
                    <h6 class="text-primary pe-1 text-nowrap">{{ 'user-details-form.office-address' | translate }}</h6>
                    <hr class="w-100 m-0 pb-2" />
                </div>
                <address-form
                    [(address)]="business.address"
                    [showToggleButton]="false"
                    [disabled]="disabled"
                    [requireAll]="requireAll"
                ></address-form>
                <div class="pt-2 mb-1"></div>
                <div class="d-flex align-items-center">
                    <h6 class="text-primary pe-1 text-nowrap">{{ 'user-details-form.business-address' | translate }}</h6>
                    <hr class="w-100 m-0 pb-2" />
                </div>
                <address-form
                    [(address)]="business.businessAddress"
                    [showToggleButton]="false"
                    [disabled]="disabled"
                    [requireAll]="requireAll"
                ></address-form>
            </div>
        </ng-container>
    </ng-template>
</form>
