import { formatNumber } from '@angular/common'
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import BigNumber from 'bignumber.js'
import { environment } from 'src/environments/environment'

@Pipe({ name: 'amount' })
export class AmountPipe implements PipeTransform {
    public static readonly DEFAULT_DECIMAL_PLACES = environment.layout === 'metal' ? 8 : 2
    constructor(@Inject(LOCALE_ID) private locale: string) {}
    public transform(
        value: string | number | null | undefined,
        decimalPlaces: number | null | undefined = 2,
        // shows null as 0 if set to false
        allowNull = false
    ): string | null | undefined {
        if (typeof decimalPlaces !== 'number' || decimalPlaces < 0 || typeof decimalPlaces === 'undefined') {
            decimalPlaces = AmountPipe.DEFAULT_DECIMAL_PLACES
        }
        const roundingMode = environment.layout === 'metal' ? 4 : 1
        return (value === null || value === undefined) && allowNull
            ? null
            : formatNumber(
                  new BigNumber(value ? value : 0).decimalPlaces(decimalPlaces, roundingMode).toString() as any,
                  this.locale,
                  `1.${
                      decimalPlaces < AmountPipe.DEFAULT_DECIMAL_PLACES
                          ? decimalPlaces
                          : AmountPipe.DEFAULT_DECIMAL_PLACES
                  }-${decimalPlaces}`
              )
    }
}
