<div class="modal-header">
    <h5 class="modal-title">{{ 'common.send-message' | translate }}</h5>
    <button type="button" class="btn btn-round close close-modal" (click)="activeModal.dismiss()">&times;</button>
</div>
<div class="modal-body">
    <div class="row mb-1">
        <div class="form-group col-sm-12">
            <ng-select
                [items]="modes"
                [(ngModel)]="mode"
                bindValue="value"
                bindLabel="label"
                [clearable]="false"
                [searchable]="false"
                name="modes"
                (change)="handleModeChange($event)"
            >
            </ng-select>
        </div>
        <div class="col-12" *ngIf="mode === 'specify'">
            <ng-select
                id="recipients"
                name="recipients"
                [placeholder]="'common.search-recipient' | translate"
                (change)="addRecipient($event)"
                [items]="usersStream | async"
                [typeahead]="recipientsAutoComplete"
                [clearable]="false"
                [closeOnSelect]="false"
            >
                <ng-template ng-option-tmp let-user="item">
                    <div class="d-flex align-items-center justify-content-center">
                        <user-avatar *ngIf="user" [user]="user" size="sm" elementClass="me-2"></user-avatar>
                        <div class="w-100">
                            <div>{{ user.name }}</div>
                            <small class="text-medium">{{ user.email }}</small>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
        <div class="col-12" *ngIf="mode === 'association'">
            <ng-select
                id="association"
                name="association"
                [items]="associations"
                [placeholder]="'common.select-association' | translate"
                (change)="setSelectedAssociation($event)"
                [clearable]="true"
                (clear)="setSelectedAssociation($event)"
                [searchable]="!selectedAssociation"
                bindLabel="name"
                [loading]="isGettingAssociations"
            >
                <ng-template ng-label-tmp let-item="item">
                    {{ item.name }} <small class="text-medium">{{ item.email }}</small>
                </ng-template>
                <ng-template ng-option-tmp let-user="item">
                    <div class="d-flex align-items-center justify-content-center">
                        <user-avatar *ngIf="user" [user]="user" size="sm" elementClass="me-2"></user-avatar>
                        <div class="w-100">
                            <div>{{ user.name }}</div>
                            <small class="text-medium">{{ user.email }}</small>
                        </div>
                    </div>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div *ngIf="mode === 'specify' && recipients.length > 0" class="row mb-1">
        <div class="col">
            <span class="text-muted">{{ 'common.recipients' | translate }} </span>
            <span
                *ngFor="let recipient of recipients"
                (click)="removeRecipient(recipient)"
                class="label label-sm label-primary me-1 mb-1 clickable recipient"
                [ngClass]
            >
                {{ recipient.email }}
            </span>
        </div>
    </div>
    <mail-form [mail]="mail" [files]="files" #mailForm></mail-form>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-w-sm" [ngClass]="{ loading: isLoading }" [disabled]="isLoading" (click)="submit()">
        {{ 'common.send' | translate }}
    </button>
</div>
