import { Injectable } from '@angular/core'
import { CanLoad } from '@angular/router'
import { Observable } from 'rxjs'
import { SessionService } from '../common/services/session.service'

@Injectable()
export class MerchantAuthGuard implements CanLoad {
    constructor(private session: SessionService) {}
    public canLoad(): boolean | Observable<boolean> | Promise<boolean> {
        return this.session.isAuthenticated() && this.session.user.type === 'business'
    }
}
