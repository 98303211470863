import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { environment } from 'src/environments/environment'
import { IUser, Mail } from '../../api-interfaces'

export interface Template {
    subject: string
    group: string
    name: string
    message: string
}

@Component({
    selector: 'mail-form',
    templateUrl: 'mail-form.component.html',
})
export class MailFormComponent implements OnInit {
    @Input()
    public user: IUser
    @Input()
    public mail: Mail
    @Input()
    public files: File[] = []
    @Input()
    public templates: Template[] = []
    @Input()
    public selectedTemplate: Template
    @Input()
    public useName = true

    @ViewChild('form')
    public form: NgForm

    public isLoading = false

    public faTimes = faTimes
    public editorModules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] as string[] }],
            ['clean'],
            ['link'],
        ],
    }

    constructor(public activeModal: NgbActiveModal, public translate: TranslateService) {}

    public ngOnInit(): void {
        if (this.selectedTemplate) {
            this.onSelectTemplate()
        } else {
            this.reset()
        }
    }

    public get recipient(): string {
        return this.user?.name ?? '<NAME>'
    }

    public onSelectTemplate(): void {
        this.mail.subject = this.selectedTemplate.subject
        this.mail.content = this.buildBaseMessage(this.recipient, this.selectedTemplate.message)
    }

    public isValid(): boolean {
        return !!this.form.valid
    }

    public isSubmitted(): boolean {
        return !!this.form.submitted
    }

    private reset(): void {
        this.mail.subject = `Message from ${environment.displayName}`
        this.mail.content = this.buildBaseMessage(this.recipient, '<p>...</p>')
    }

    private buildBaseMessage(name: string, content: string): string {
        return `<p>Hi ${
            this.useName ? name : '[username]'
        } 👋</p>${content}<p>If you have any concerns, feel free to contact us.</p><p>Regards,</p><p>${
            environment.displayName
        } team</p>`
    }
}
