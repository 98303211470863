import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { IonicModule } from '@ionic/angular'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import * as Color from 'color'
import { NgProgressModule, NG_PROGRESS_CONFIG } from 'ngx-progressbar'
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NgProgressRouterModule } from 'ngx-progressbar/router'
import { CoreModule } from './app/core/core.module'
import { AppErrorHandler } from './app/error-handler.service'
import { StatusMessageModule } from './app/status-message/status-message.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CommonModule } from './common/common.module'
import { MerchantAuthGuard } from './merchant/merchant.auth-guard'
import { Timestamp } from '../app/Timestamp'

const style = getComputedStyle(document.body)
const color = Color(style.getPropertyValue('--bs-primary').trim())

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
    return new TranslateHttpLoader(new HttpClient(httpBackend), '/assets/i18n/', '.json?cb=' + Timestamp.stamp)
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'md',
        }),
        HttpClientModule,
        CommonModule,
        StatusMessageModule,
        CoreModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        NgProgressModule,
        NgProgressHttpModule,
        NgProgressRouterModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler,
        },
        {
            provide: NG_PROGRESS_CONFIG,
            useValue: {
                spinner: false,
                color: color.hex(),
            },
        },
        MerchantAuthGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
