import { Injectable } from '@angular/core'
import { QueryEntity } from '@datorama/akita'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ICurrency } from 'src/app/common/api-interfaces'
import { CurrenciesState, CurrenciesStore } from './currencies.store'
import { SessionService } from '../../common/services/session.service'

@Injectable({ providedIn: 'root' })
export class CurrenciesQuery extends QueryEntity<CurrenciesState> {
    public preferredCurrency$ = combineLatest(this.selectAll(), this.session.userStream).pipe(
        map(([currencies, user]) => currencies.find(currency => currency.code === user.preferredCurrency.code)!)
    )

    constructor(protected store: CurrenciesStore, private session: SessionService) {
        super(store)
    }

    public selectAllByPermission(): Observable<ICurrency[]> {
        return combineLatest(this.selectAll(), this.session.userStream).pipe(
            map(([currencies, user]) => {
                if (user.permissions.find(permission => permission.startsWith('currencies:'))) {
                    const index = this.session.user.permissions.findIndex(permission =>
                        permission.startsWith('currencies:')
                    )

                    if (index !== -1) {
                        const currencyPermissions = this.session.user.permissions[index].split(':')[1].split(',')
                        return currencies.filter(currency => currencyPermissions.includes(currency.code))
                    } else {
                        return currencies
                    }
                } else {
                    return currencies
                }
            })
        )
    }
}
