import { Beneficiary } from './beneficiary.model'
import { Currency } from './currency.model'
import { Wallet } from './wallet.model'
import {
    Alert,
    ICard,
    ICurrency,
    Transaction as ITransaction,
    TransactionMethod,
    TransactionStatus,
    TransactionType,
} from '../../api-interfaces'

export const methodMap: { [K in TransactionMethod]: string } = {
    internal: 'INTERNAL',
    card: 'CARD',
    crypto: 'CRYPTO',
    local: 'LOCAL',
    swift: 'SWIFT',
    'payment-card': 'PAYMENT_CARD',
}

export class Transaction implements ITransaction {
    public id: string
    public amount: string
    public requestedAmount: string | null
    public settledAmount: string
    public status: TransactionStatus
    public type: TransactionType
    public method: TransactionMethod
    public baseNewBalance: string | null
    public counterNewBalance: string | null
    public fixedFee: string
    public relativeFee: string
    public comment: string | null
    public reference: string | null
    public externalReference: string | null
    public currency: ICurrency
    public requestedCurrency: ICurrency | null
    public baseWallet: Wallet | null
    public counterWallet: Wallet | null
    public settledAt: string | null
    public beneficiary: Beneficiary | null
    public card: ICard | null
    public faulty: boolean
    public alerts: Alert[]
    public precision: number
    public totalFee: string
    public receivedAmount: string | null
    public receivedCurrency: ICurrency | null
    public purpose: string | null
    public sourceOfFunds: string | null
    public automated?: boolean
    public tags?: string[]
    public usdRate: string | null
    public createdAt: string
    public updatedAt: string

    constructor(data?: ITransaction) {
        if (data) {
            Object.assign(this, data)
            this.currency = new Currency(data.currency)
            if (data.requestedCurrency) {
                this.requestedCurrency = new Currency(data.requestedCurrency)
            }
            if (data.receivedCurrency) {
                this.receivedCurrency = new Currency(data.receivedCurrency)
            }
            if (data.baseWallet) {
                this.baseWallet = new Wallet(data.baseWallet)
            }
            if (data.counterWallet) {
                this.counterWallet = new Wallet(data.counterWallet)
            }
            if (data.beneficiary) {
                this.beneficiary = new Beneficiary(data.beneficiary)
            }
        }
    }

    public isPlatformTransfer(): boolean {
        return !!(
            this.type === 'transfer' &&
            ((this.baseWallet && this.baseWallet.isRootWallet()) ||
                (this.counterWallet && this.counterWallet.isRootWallet()))
        )
    }
}
