import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import BigNumber from 'bignumber.js'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { FeeType, ICurrency, IFee, IUser, RPCResult, Transaction, TransactionMethod } from '../../api-interfaces'
import { Currency } from '../../models/accounting/currency.model'
import { Fee } from '../../models/accounting/fee.model'
import { calculateFee } from '../../transactions.mixins'

@Injectable({
    providedIn: 'root',
})
export class FeeService {
    constructor(private http: HttpClient) {}

    public fetch(fee: {
        type: FeeType
        method: TransactionMethod
        currency: ICurrency
        amount?: string
        user?: IUser | null
        volume?: string | null
    }): Observable<Fee | null> {
        const params: Partial<IFee> = {
            type: fee.type,
            method: fee.method,
            currency: fee.currency,
            user: fee.user,
        }
        if (fee.volume !== undefined) {
            params.volume = fee.volume
        }

        return this.http
            .post<RPCResult<IFee | null>>(
                '/fees',
                {
                    id: Math.round(Math.random() * 10000),
                    jsonrpc: '2.0',
                    method: 'findByPrimaryAttributes',
                    params,
                },
                {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json-rpc',
                    }),
                }
            )
            .pipe(map(response => (response.result ? new Fee(response.result) : null)))
    }

    public determineFee(currentTransaction: Partial<Transaction>, fee: Partial<Fee> | null): Partial<Transaction> {
        const transaction = currentTransaction
        const fixedFee =
            fee && fee.fixed && transaction.currency
                ? Currency.convert(fee.fixed, fee.currency!, transaction.currency)
                : '0'
        const relativeFee = fee && fee.relative ? fee.relative : '0'

        transaction.fixedFee = fixedFee || undefined
        transaction.relativeFee = relativeFee || undefined

        if (fee && fee.min) {
            const minFee = Currency.convert(fee.min, fee.currency!, transaction.currency!)
            if (new BigNumber(calculateFee(transaction as Transaction)).isLessThan(minFee)) {
                transaction.fixedFee = minFee
                transaction.relativeFee = '0'
            }
        }
        if (fee && fee.max) {
            const maxFee = Currency.convert(fee.max, fee.currency!, transaction.currency!)
            if (new BigNumber(calculateFee(transaction as Transaction)).isGreaterThan(maxFee)) {
                transaction.fixedFee = maxFee
                transaction.relativeFee = '0'
            }
        }
        return transaction
    }

    public getRelatedFeesWithVolume(feeId: string): Observable<Fee[]> {
        return this.http.get<Fee[]>(`/admin/fees/${feeId}/volumes`).pipe(map(fees => fees.map(fee => new Fee(fee))))
    }
}
