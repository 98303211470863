import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons'
import BigNumber from 'bignumber.js'
import { currencySymbolMap } from 'currency-symbol-map'
import { forkJoin, merge, Observable, of, Subject } from 'rxjs'
import { debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators'
import {
    IBeneficiary,
    ICurrency,
    IFee,
    Paginated,
    RPCResult,
    TransactionMethod,
    TransactionType,
} from '../api-interfaces'
import { Currency } from '../models/accounting/currency.model'
import { SessionService } from '../services/session.service'

@Component({
    selector: 'fee-preview',
    templateUrl: 'fee-preview.component.html',
})
export class FeePreviewComponent implements OnInit, OnChanges {
    public faCircleNotch = faCircleNotch
    public currencySymbolMap = currencySymbolMap
    @Input()
    /* Whether to show a currency symbol prefix. Eg. $1.00 */
    public prefix = false
    @Input()
    public currency: ICurrency | null
    @Input()
    public type: TransactionType
    @Input()
    public method: TransactionMethod
    @Input()
    public amount: string
    @Input()
    public spreadUp: boolean | undefined = undefined
    @Output()
    public readonly fixedFeeChange = new EventEmitter<string | null>()
    @Output()
    public readonly relativeFeeChange = new EventEmitter<string | null>()
    @Output()
    public readonly beneficiaryChange = new EventEmitter<IBeneficiary | null>()
    @Output()
    public readonly currencyChange = new EventEmitter<Currency | null>()
    public isLoading = true
    private fetchEvent = new Subject<void>()
    private currencies: ICurrency[] = []
    private fxSpread: IFee | null = null
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public feeStream: Observable<IFee | null> = merge(of(undefined), this.fetchEvent).pipe(
        tap(() => {
            this.isLoading = true
        }),
        debounceTime(200),
        switchMap(() =>
            !(this.type && this.method && this.currency)
                ? of(null)
                : forkJoin([
                      // fetch the fee itself for the current set of { method, type, currency }
                      this.http.post<RPCResult<IFee | null>>(
                          '/fees',
                          {
                              id: Math.round(Math.random() * 10000),
                              jsonrpc: '2.0',
                              method: 'findByPrimaryAttributes',
                              params: {
                                  type: this.type,
                                  method: this.method,
                                  user: this.session.user ? { id: this.session.user.id } : null,
                                  currency: this.currency ? { code: this.currency.code } : null,
                              },
                          },
                          {
                              headers: new HttpHeaders({
                                  'Content-Type': 'application/json-rpc',
                              }),
                          }
                      ),
                      this.spreadUp === undefined
                          ? of(undefined)
                          : this.http.post<RPCResult<IFee | null>>(
                                '/fees',
                                {
                                    id: Math.round(Math.random() * 10000),
                                    jsonrpc: '2.0',
                                    method: 'findByPrimaryAttributes',
                                    params: {
                                        type: 'fx-spread',
                                        method: 'internal',
                                        user: this.session.user ? { id: this.session.user.id } : null,
                                        currency: this.currency ? { code: this.currency.code } : null,
                                    },
                                },
                                {
                                    headers: new HttpHeaders({
                                        'Content-Type': 'application/json-rpc',
                                    }),
                                }
                            ),
                  ]).pipe(
                      tap(([{ result }, fxSpreadResponse]) => {
                          this.fixedFeeChange.emit(result && result.fixed)
                          this.relativeFeeChange.emit(result && result.relative)
                          this.currencyChange.emit(result && new Currency(result.currency))
                          if (this.type === 'deposit') {
                              this.beneficiaryChange.emit(result && result.beneficiary)
                          }
                          if (fxSpreadResponse) {
                              this.fxSpread = fxSpreadResponse.result
                          }
                      }),
                      map(([{ result }]) => (result ? result : ({ relative: '0.0', currency: this.currency } as IFee))),
                      finalize(() => {
                          this.isLoading = false
                      })
                  )
        )
    )

    constructor(private http: HttpClient, private session: SessionService) {}

    public ngOnInit(): void {
        this.http.get<Paginated<ICurrency>>('/currencies', { params: { limit: 100 + '' } }).subscribe(response => {
            this.currencies = response.data
            this.fetchEvent.next()
        })
    }

    public ngOnChanges(): void {
        this.fetchEvent.next()
    }

    public calculateAmount(fee: IFee): string {
        if (!this.currency || !this.currencies) {
            return '0.0'
        }
        const found = this.currencies.find(currency => this.currency && currency.code === this.currency.code)
        const precision = found ? found.decimalPlaces : 2
        const relativeFeeAmount = new BigNumber(this.amount || 0).times(fee.relative || 0).toFixed(precision || 8, 1)
        return new BigNumber(this.calculateFixedFee(fee)).plus(relativeFeeAmount).toFixed(precision || 8, 1)
    }

    private convert(amount: string, baseCurrency: ICurrency, counterCurrency: ICurrency): string {
        const base = this.currencies.find(currency => currency.code === baseCurrency.code)
        const counter = this.currencies.find(currency => currency.code === counterCurrency.code)
        if (!counter || !base) {
            return '0'
        }
        return Currency.convert(
            amount,
            base,
            counter,
            this.spreadUp !== undefined && this.fxSpread ? this.fxSpread : undefined,
            this.spreadUp
        )
    }

    private calculateFixedFee(fee: IFee): string {
        return new BigNumber(
            fee.fixed && this.currency ? this.convert(fee.fixed, fee.currency, this.currency) : 0
        ).toFixed(8, 1)
    }
}
