import { Beneficiary } from './beneficiary.model'
import { Currency } from './currency.model'
import { FeeType, IFee, TransactionMethod } from '../../api-interfaces'
import { User } from '../core/user.model'

export const feeTypeMap: { [K in FeeType]: string } = {
    'account-fee': 'Account Fee',
    'business-account-fee': 'Business Account Fee',
    'opening-fee': 'Opening Fee',
    'business-opening-fee': 'Business Opening Fee',
    'card-order': 'Card Order Fee',
    'fx-spread': 'Exchange Rate Spread',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    transfer: 'Transfer',
    conversion: 'Conversion',
    investment: 'Investment',
    trade: 'Trade',
    redeem: 'Redeem',
    service: 'Buy & Sell',
    gst: 'VAT',
    payment: 'Payment',
    custody: 'Custody',
    shop: 'Shop',
}

export const feeMethodMap: { [K in TransactionMethod]: string } = {
    internal: 'Internal',
    card: 'Topup Card',
    crypto: 'Crypto',
    local: 'LOCAL',
    swift: 'SWIFT',
    'payment-card': 'Payment Card',
}

export class Fee implements IFee {
    public id: string
    public type: FeeType
    public method: TransactionMethod
    public fixed: string | null
    public relative: string | null
    public max: string | null
    public min: string | null
    public minValue: string | null
    public volume: string | null
    public currency: Currency
    public user: User | null
    public beneficiary: Beneficiary | null
    public createdAt: string
    public updatedAt: string

    public constructor(data?: Partial<IFee>) {
        if (data) {
            Object.assign(this, data)
            if (data.currency) {
                this.currency = new Currency(data.currency)
            }
            if (data.user) {
                this.user = new User(data.user)
            }
            if (data.beneficiary) {
                this.beneficiary = new Beneficiary(data.beneficiary)
            }
        }
    }

    public isGlobal(): boolean {
        return this.user === null
    }

    public isFallback(currency: Currency): boolean {
        return this.currency.code !== currency.code
    }

    /**
     * Determines if fee is from wl / association
     * @returns boolean
     */
    public isGroupFee(user: User | null): boolean {
        return !!this.user && !!user && this.user.id !== user.id
    }

    public hasFixedFee(): boolean {
        return !['fx-spread', 'service', 'gst'].includes(this.type)
    }

    public hasRelativeFee(): boolean {
        return !['account-fee', 'business-account-fee', 'opening-fee', 'business-opening-fee'].includes(this.type)
    }

    public hasMaxOrMin(): boolean {
        return !['account-fee', 'fx-spread', 'business-account-fee', 'opening-fee', 'business-opening-fee'].includes(
            this.type
        )
    }

    public hasMinValue(): boolean {
        return ['transfer', 'deposit', 'withdrawal'].includes(this.type)
    }

    public canHaveVolume(): boolean {
        return ['transfer', 'deposit', 'withdrawal', 'account-fee', 'business-account-fee', 'fx-spread'].includes(
            this.type
        )
    }
}

export function getFeeType(transactionType: string): FeeType {
    const feeMap: Record<string, FeeType> = {
        deposit: 'deposit',
        withdrawal: 'withdrawal',
        transfer: 'transfer',
        conversion: 'conversion',
        investment: 'investment',
        trade: 'trade',
        redeem: 'redeem',
        payment: 'payment',
        // Use transfer fee for invoice
        invoice: 'transfer',
        'invoice-p2p': 'transfer',
        'card-order': 'card-order',
        'account-fee': 'account-fee',
    }
    return feeMap[transactionType]
}
