<h6>{{ 'permission-select.payment-methods' | translate }}</h6>
<div *ngFor="let permission of paymentPermissions; let i = index">
    <label class="form-check form-switch">
        <input
            type="checkbox"
            name="payment-{{ i }}"
            class="form-check-input"
            [value]="permission"
            (change)="toggleMethod(permission, $event.target.checked)"
            [checked]="isPermissionSelected(permission)"
            [disabled]="!canUpdate"
        />
        <span class="form-check-label font-md text-uppercase">
            {{ getPermissionLabel(permission) }}
        </span>
    </label>
    <ng-container *ngIf="isPermissionSelected(permission)">
        <ng-container *ngFor="let type of ['deposit', 'withdrawal']; let i = index">
            <div class="ms-4 mb-2 d-inline-block">
                <label class="form-check form-switch">
                    <input
                        type="checkbox"
                        [name]="permission + '-' + type"
                        class="form-check-input"
                        [value]="type"
                        (change)="toggleArrayPermissions(permission, type)"
                        [checked]="isArrayPermissionSelected(permission, type)"
                        [disabled]="!canUpdate"
                    />
                    <span class="form-check-label font-md text-uppercase">
                        {{ 'common.' + type | translate | titlecase }}
                    </span>
                </label>
            </div>
        </ng-container>
    </ng-container>
</div>
<h6 class="mt-3">{{ 'common.features' | translate }}</h6>
<div *ngFor="let permission of featurePermissions; let i = index">
    <label class="form-check form-switch">
        <input
            type="checkbox"
            name="features-{{ i }}"
            class="form-check-input"
            [value]="permission"
            (change)="toggleFeature(permission, $event.target.checked)"
            [checked]="permissions.indexOf(permission) !== -1"
            [disabled]="!canUpdate"
        />
        <span class="form-check-label font-md">
            {{ getPermissionLabel(permission) }}
        </span>
    </label>
</div>
<ng-container *ngIf="showInvoiceSelect()">
    <div>
        <label class="form-check form-switch">
            <input
                type="checkbox"
                name="invoices"
                class="form-check-input"
                [value]="'invoices'"
                (change)="toggleMethod('invoices', $event.target.checked)"
                [checked]="isPermissionSelected('invoices')"
                [disabled]="!canUpdate"
            />
            <span class="form-check-label font-md">
                {{ getPermissionLabel('invoices') }}
            </span>
        </label>
        <ng-container *ngIf="isPermissionSelected('invoices')">
            <ng-container *ngFor="let type of ['account', 'email']; let i = index">
                <div class="ms-4 mb-2 d-inline-block">
                    <label class="form-check form-switch">
                        <input
                            type="checkbox"
                            [name]="'invoices' + '-' + type"
                            class="form-check-input"
                            [value]="type"
                            (change)="toggleArrayPermissions('invoices', type)"
                            [checked]="isArrayPermissionSelected('invoices', type)"
                            [disabled]="!canUpdate"
                        />
                        <span class="form-check-label font-md">
                            {{ 'common.' + type | translate | titlecase }}
                        </span>
                    </label>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="showCurrencies">
    <h6 class="mt-3">{{ 'common.currencies' | translate }}</h6>
    <div class="form-group">
        <ng-select
            name="currencies"
            [items]="currencies"
            [searchable]="false"
            [clearable]="true"
            [multiple]="true"
            [closeOnSelect]="false"
            [placeholder]="'permission-select.all-currencies-enabled' | translate"
            bindLabel="code"
            (add)="toggleArrayPermissions('currencies', $event.code)"
            (remove)="toggleArrayPermissions('currencies', $event.value.code)"
            (clear)="clearArrayPermissions('currencies')"
            [ngModel]="selectedCurrencies"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label">{{ item.code }}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">{{ item.code }}</ng-template>
        </ng-select>
    </div>
</ng-container>
<ng-container *ngIf="showCardPrograms">
    <h6 class="mt-3">{{ 'common.card-programs' | translate }}</h6>
    <div class="form-group">
        <ng-select
            name="cardPrograms"
            [items]="cardPrograms"
            [searchable]="false"
            [clearable]="true"
            [multiple]="true"
            [closeOnSelect]="false"
            (add)="toggleArrayPermissions('card-programs', $event.toLowerCase())"
            (remove)="toggleArrayPermissions('card-programs', $event.value.toLowerCase())"
            (clear)="clearArrayPermissions('card-programs')"
            [ngModel]="selectedCardPrograms"
        >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label">{{ item }}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">{{ item }}</ng-template>
        </ng-select>
    </div>
</ng-container>
