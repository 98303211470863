import { HttpClient, HttpParams } from '@angular/common/http'
import { Component, EventEmitter, ViewChild } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators'
import { Association, IUser, Mail } from '../../common/api-interfaces'
import { readFileAsDataURL } from '../../common/common.mixin'
import { MailFormComponent } from '../../common/mails/mail-form/mail-form.component'
import { ToastrService } from '../../common/services/toastr.service'

@Component({
    selector: 'global-message-modal',
    templateUrl: 'global-message-modal.component.html',
    styleUrls: ['global-message-modal.component.scss'],
})
export class GlobalMessageModalComponent {
    public mail: Partial<Mail> = {}
    public files: File[] = []
    // If specify is selected
    public recipients: IUser[] = []
    public mode: 'all' | 'active' | 'specify' | 'association' = 'all'
    public modes = [
        {
            label: 'Send to all accounts',
            value: 'all',
        },
        {
            label: 'Send to all verified accounts',
            value: 'verified',
        },
        {
            label: 'Send to select accounts',
            value: 'specify',
        },
        {
            label: `Send to select association group`,
            value: 'association',
        },
        {
            label: `Send to all business accounts`,
            value: 'business',
        },
    ]
    @ViewChild('mailForm')
    public mailForm: MailFormComponent
    public recipientsAutoComplete = new EventEmitter<string>()
    public usersStream = this.recipientsAutoComplete.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(search =>
            this.http.get<IUser[]>('/users', {
                params: new HttpParams().set('limit', '25').set('search', search),
            })
        )
    )
    public associations: Association[] = []
    public isGettingAssociations = false
    public selectedAssociation: IUser | null
    public isLoading = false

    constructor(
        public activeModal: NgbActiveModal,
        private http: HttpClient,
        private toastr: ToastrService,
        public translate: TranslateService
    ) {}

    public async submit(): Promise<void> {
        if (!this.mailForm.isValid()) {
            return
        }
        this.isLoading = true
        this.http
            .post('/admin/mails/global', {
                mode: this.mode,
                subject: this.mail.subject,
                userIds: this.mode === 'specify' ? this.recipients.map(r => r.id) : [],
                associationId: this.selectedAssociation ? this.selectedAssociation.id : undefined,
                content: this.mail.content,
                attachments: await Promise.all(
                    this.files.map(async file => ({
                        name: encodeURIComponent(file.name),
                        base64: await readFileAsDataURL(file),
                    }))
                ),
            })
            .subscribe(() => {
                this.toastr.success(this.translate.instant('common.message-sent'))
                this.activeModal.close()
            })
    }

    public getAssociations(): void {
        this.isGettingAssociations = true
        this.http.get<Association[]>('/admin/associations').subscribe(associations => {
            this.associations = associations
            this.isGettingAssociations = false
        })
    }

    public addRecipient(user: IUser | null): void {
        if (user && !this.recipients.find(u => u.id === user.id)) {
            this.recipients.push(user)
        }
    }

    public setSelectedAssociation(user: IUser | null): void {
        if (user) {
            this.selectedAssociation = user
        } else {
            this.selectedAssociation = null
        }
    }

    public removeRecipient(user: IUser): void {
        this.recipients.splice(
            this.recipients.findIndex(u => u.id === user.id),
            1
        )
    }

    public handleModeChange(mode: { label: string; value: string }): void {
        if (mode && mode.value === 'association') {
            this.getAssociations()
        }
    }
}
