import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { StorageService } from '../storage.service'

export interface WhitelabelEnv {
    id: string
    name: string
    domain: string
    owner: string
    horizontalLogo: string
    verticalLogo: string
    symbolLogo: string
    favicon: string
}

@Injectable({ providedIn: 'root' })
export class WhitelabelService {
    private whitelabel = new BehaviorSubject<WhitelabelEnv>(window.WHITELABEL ?? null)
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public whitelabel$ = this.whitelabel.asObservable()

    constructor(private http: HttpClient, private storage: StorageService) {}

    public getWhitelabel(): WhitelabelEnv | null {
        return this.whitelabel.getValue()
    }

    public getDisplayName(): string {
        const whitelabel = this.getWhitelabel()
        return whitelabel ? whitelabel.name : environment.displayName
    }

    public checkIfCorrectWhitelabelForUser(userId: string): void {
        this.http.post<{ domain: string } | null>(`/whitelabels/user-check`, { userId }).subscribe(response => {
            if (response && response.domain !== window.location.origin) {
                this.storage.removeItem('token')
                this.storage.removeItem('refreshToken')
                this.storage.removeItem('user')
                window.location.href = response.domain
            }
        })
    }
}
