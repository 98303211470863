<form class="form-validation e2e-mail-form" [ngClass]="{ 'ng-submitted': form.submitted }" #form="ngForm" autocomplete="false" novalidate>
    <div class="row">
        <div class="form-group col">
            <label class="form-control-label">{{ 'common.subject' | translate }}</label>
            <input
                type="text"
                class="form-control"
                name="subject"
                [placeholder]="'common.subject' | translate"
                [(ngModel)]="mail.subject"
                #messageSubject="ngModel"
                required
            />
            <div *ngIf="messageSubject.invalid && (form.submitted || messageSubject.dirty)" class="error-messages">
                <div class="form-control-feedback" *ngIf="messageSubject?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
            </div>
        </div>
        <div *ngIf="templates.length > 0" class="form-group col-sm-12">
            <label class="form-control-label" for="templates">{{ 'common.template' | translate }}</label>
            <ng-select
                [items]="templates"
                bindLabel="name"
                groupBy="group"
                [(ngModel)]="selectedTemplate"
                (change)="onSelectTemplate()"
                [placeholder]="'common.select-template' | translate"
                name="templates"
            >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                    {{ item.group | uppercase }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    {{ item.name }}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <quill-editor
        name="message"
        [(ngModel)]="mail.content"
        [modules]="editorModules"
        #messageBody="ngModel"
        [sanitize]="true"
        required
    ></quill-editor>
    <div *ngIf="messageBody.invalid && (form.submitted || messageBody.dirty)" class="error-messages">
        <div class="form-control-feedback" *ngIf="messageBody?.errors?.required">{{ 'common.this-is-required' | translate }}</div>
    </div>
    <div class="mt-3">
        <dropzone [files]="files"></dropzone>
    </div>
</form>
