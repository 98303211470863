import { HttpClient, HttpParams } from '@angular/common/http'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { faArrowRight } from '@fortawesome/pro-duotone-svg-icons'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { cloneDeep, omit } from 'lodash'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, finalize, flatMap, switchMap, tap } from 'rxjs/operators'
import { BeneficiaryDetailsFormComponent } from './beneficiary-details-form/beneficiary-details-form.component'
import { BeneficiaryMethod, BeneficiaryType, IUser, Paginated } from '../../common/api-interfaces'
import { Beneficiary } from '../models/accounting/beneficiary.model'
import { Currency } from '../models/accounting/currency.model'
import { User } from '../models/core/user.model'
import { BeneficiaryBookComponent } from '../payment/beneficiary-book/beneficiary-book.component'
import { ConfirmationResult, ConfirmationService } from '../services/confirmation.service'
import { ToastrService } from '../services/toastr.service'

@Component({
    selector: 'beneficiary-form',
    templateUrl: 'beneficiary-form.component.html',
})
export class BeneficiaryFormComponent implements OnInit, OnDestroy {
    @Input()
    public item: Beneficiary
    @Input()
    public compact = false
    @Input()
    public fixateType = false
    @Input()
    public hideFields: ('owner' | 'type' | 'method' | 'currency')[] = []
    @Input()
    public showTemplatesButton = false
    @Output()
    public readonly onSave = new EventEmitter<void>()
    @Output()
    public readonly onDelete = new EventEmitter<void>()
    @Output()
    public readonly onDiscard = new EventEmitter<void>()

    public copy: Partial<Beneficiary> = {}
    @ViewChild('beneficiaryForm')
    public beneficiaryForm: NgForm
    @ViewChild('beneficiaryDetailsForm')
    public beneficiaryDetailsForm: BeneficiaryDetailsFormComponent

    public isSaving = false
    public isDeleting = false
    public isUpdatingVerification = false

    public faArrowRight = faArrowRight

    public beneficiaryTypes: BeneficiaryType[] = ['template', 'deposit-instruction']
    @Input()
    public beneficiaryMethods: BeneficiaryMethod[] = ['swift', 'local', 'crypto']

    public currencies: Currency[] = []
    public owner?: IUser = undefined
    public ownerAutocomplete = new EventEmitter<string>()
    public legalEntitiesSteam = this.ownerAutocomplete.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(search =>
            this.http.get<IUser[]>('/users', {
                params: new HttpParams().set('limit', '25').set('search', search),
            })
        )
    )

    private fetchEvent = new Subject<void>()
    private fetchCurrenciesEvent = new Subject<void>()
    private subscriptions = new Subscription()

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
        public ngbModal: NgbModal,
        private confirmation: ConfirmationService,
        public translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.fetchEvent
                .pipe(switchMap(() => this.http.get<Beneficiary>(`/beneficiaries/${this.item.id}`)))
                .subscribe(beneficiary => {
                    this.item = beneficiary
                    this.copy = cloneDeep(this.item)
                    if (this.item.user) {
                        this.owner = new User(this.item.user)
                    }
                    this.fetchCurrenciesEvent.next()
                })
        )
        this.subscriptions.add(
            this.fetchCurrenciesEvent
                .pipe(
                    switchMap(() =>
                        this.http.get<Paginated<Currency>>('/currencies?limit=100', {
                            params: {
                                'filter.type': this.copy.method === 'crypto' ? 'crypto' : 'fiat',
                            },
                        })
                    )
                )
                .subscribe(response => {
                    this.currencies = response.data
                    if (this.copy && this.copy.currency) {
                        // don't break reference
                        this.copy.currency = this.currencies.find(c => c.code === this.copy.currency!.code)! || null
                    }
                })
        )

        if (this.item && this.item.id) {
            this.fetchEvent.next()
        } else {
            this.copy = cloneDeep(this.item)
            if (this.item.user) {
                this.owner = new User(this.item.user)
            }
            this.fetchCurrenciesEvent.next()
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public delete(): void {
        this.subscriptions.add(
            this.confirmation
                .show({
                    type: 'danger',
                    text: `${this.translate.instant(
                        'common.are-you-sure-want-to-delete-beneficiary'
                    )}<br>${this.translate.instant('common.you-cant-undo-action')}`,
                    confirmText: this.translate.instant('common.delete'),
                    confirmClass: 'danger',
                })
                .pipe(
                    filter(result => result === ConfirmationResult.CONFIRMED),
                    flatMap(() => {
                        this.isDeleting = true
                        return this.http.delete<void>(`/beneficiaries/${this.item.id}`)
                    }),
                    finalize(() => {
                        this.isDeleting = false
                    })
                )
                .subscribe(() => {
                    this.onDelete.emit()
                    this.toastr.success(this.translate.instant('common.beneficiary-deleted'))
                })
        )
    }

    public submit(): void {
        this.beneficiaryDetailsForm.submit()
        if (this.beneficiaryForm.invalid || !this.beneficiaryDetailsForm.isValid()) {
            throw new Error(this.translate.instant('common.form-invalid'))
        }
        const body: Record<string, any> = {
            ...this.copy,
            user: this.owner ? { id: this.owner?.id } : null,
            currency: {
                code: this.copy.currency?.code ?? null,
            },
        }

        if (!this.item.id) {
            body.userType = this.copy.type === 'deposit-instruction' && !this.copy.user ? this.copy.userType : null
        }

        this.isSaving = true
        this.subscriptions.add(
            (this.item.id
                ? this.http.put<Beneficiary>(`/beneficiaries/${this.item.id}`, body)
                : this.http.post<Beneficiary>('/beneficiaries', body)
            )
                .pipe(
                    tap(beneficiary => (this.item = beneficiary)),
                    finalize(() => {
                        this.isSaving = false
                    })
                )
                .subscribe(() => {
                    this.toastr.success(
                        `${
                            this.copy.id
                                ? this.translate.instant('common.beneficiary-updated')
                                : this.translate.instant('common.beneficiary-created')
                        }`
                    )
                    this.fetchEvent.next()
                    this.onSave.emit()
                })
        )
    }

    public onChangeMethod(method: BeneficiaryMethod): void {
        switch (method) {
            case 'swift':
            case 'local':
                if (this.copy.currency && this.copy.currency.type !== 'fiat') {
                    this.copy.currency = undefined
                }
                break
            case 'crypto':
                if (this.copy.currency && this.copy.currency.type !== 'crypto') {
                    this.copy.currency = undefined
                }
                break
        }
        this.fetchCurrenciesEvent.next()
    }

    public openBeneficiaryBook(): void {
        const modal = this.ngbModal.open(BeneficiaryBookComponent, {
            windowClass: 'modal-primary',
            size: 'lg',
        })
        modal.componentInstance.isAdmin = true
        ;(modal.componentInstance as BeneficiaryBookComponent).onSelect.subscribe(async (beneficiary: Beneficiary) => {
            this.copy = {
                ...this.copy,
                ...omit(beneficiary, [
                    'currency',
                    'type',
                    'id',
                    'method',
                    'accountHolderAddress.id',
                    'accountHolderAddress.createdAt',
                    'accountHolderAddress.updatedAt',
                    'bankAddress.id',
                    'bankAddress.createdAt',
                    'bankAddress.updatedAt',
                    'intermediateBankAddress.id',
                    'intermediateBankAddress.createdAt',
                    'intermediateBankAddress.updatedAt',
                    'userType',
                ]),
                templateId: beneficiary.id,
            }

            modal.close()
        })
    }
}
